<template>
  <Tabs v-model="tabsName" :animated="false" class="tabs_styles">
    <!--拣货复核-->
    <TabPane :label="$t('key1005849')" name="pickingReview">
      <picking-review v-if="tabsName === 'pickingReview'"></picking-review>
    </TabPane>
    <!--包装作业-->
    <TabPane :label="$t('key1001825')" name="packagingOperation">
      <packaging-operation v-if="tabsName === 'packagingOperation'"></packaging-operation>
    </TabPane>
  </Tabs>
</template>

<script>
import pickingReview from './pickingReview';
import packagingOperation from './packagingOperation';

export default {
  name: "packWorkingTab",
  data() {
    return {
      tabsName: 'pickingReview'
    };
  },
  components: {
    pickingReview,
    packagingOperation
  }
};
</script>
<style lang="less" scoped>
.tabs_styles {
  /deep/ .ivu-tabs-bar {
    border-bottom: none;
    background-color: #fff;
    padding: 8px 0 3px 0;
    margin: 0;
  }
}
</style>

