<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <div style="paddingTop:15px;">
        {{ $t('key1005659') }}
        <Input v-model.trim="pickList" clearable style="width:300px;marginLeft:15px;" autofocus ref="packListIpt" @on-enter="pickStart"></Input>
      </div>
    </div>
    <h2 class="title">{{ $t('key1005660') }} </h2>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        :loading="tableLoading"
        border
        max-height="650"
        :columns="packingColumn"
        :data="packingData">
      </Table>
    </div>
    <!-- 拣货单异常 -->
    <Modal v-if="pickingListStatus" v-model="pickingListModelStatus" width="500" :title="$t('key1005649')">
      <div style="padding:10px 20px;">
        <Row>
          <Col span="6">
            <div>
              <Icon type="md-information-circle" color="#2b85e4" style="font-size:50px"></Icon>
            </div>
          </Col>
          <Col span="16" style="font-size:16px" v-if="errorCode === 116103">
            {{ $t('key1005650').replace('{pickList}', packingText) }}
          </Col>
          <Col span="16" style="font-size:16px"
            v-else-if="errorCode === packWorking">{{ $t('key1005651').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116106">{{ $t('key1005652').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116107">{{ $t('key1005653').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116109">{{ $t('key1005654').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116110">{{ $t('key1005655').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116115">{{ $t('key1005656').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else-if="errorCode === 116011">{{ $t('key1005657').replace('{pickList}', packingText) }}
          </Col>
          <Col
            span="16"
            style="font-size:16px"
            v-else>{{ $t('key1005658').replace('{pickList}', packingText) }}
          </Col>
        </Row>
      </div>
      <template #footer>
        <Button style="marginRight:210px;" @click="cancel">{{ $t('key1000588') }}</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { aliasb1f6d857944145d4ab8c800b5a1f7612 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import {getUserNameByUserId, commonSessionStorage} from "@/utils/common";

export default {
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pickList: null, // 拣货单单号
      loading: false,
      packingPickingPackageCount: {
        monthCount: 0,
        todayCount: 0,
        yearCount: 0
      },
      packingData: [],
      packingColumn: [
        {
          title: 'NO',
          width: 60,
          align: 'center',
          key: 'index',
          fixed: 'left',
          type: 'index',
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003602'),
          key: 'pickingGoodsNo',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1002452'),
          key: 'packageGoodsType',
          align: 'center',
          render: (h, params) => {
            const type = {
              MM: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000178'),
              SS: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000179'),
              MIX: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005661')
            };
            return h('span', type[params.row.packageGoodsType]);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1003104'),
          key: 'scanStartTime',
          align: 'center',
          sortable: true,
          render: (h, params) => {
            return h('div', this.$uDate.dealTime(params.row.scanStartTime));
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005662'),
          key: 'workTime',
          align: 'center'
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005663'),
          key: 'packageProgress',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.packageNum + '/' + params.row.totalPackageNum);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005664'),
          key: 'productProgress',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.goodsNum + '/' + params.row.totalGoodsNum);
          }
        }, {
          title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005665'),
          key: 'userId',
          align: 'center',
          render: (h, params) => {
            return h('div', getUserNameByUserId(params.row.userId,v));
          }
        }
      ],
      pickingListStatus: false,
      pickingListModelStatus: false,
      errorCode: null,
      packingText: ''
    };
  },
  methods: {
    getPickType(pickType) {
      let pickTypeObj = {
        SS: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005666'),
        SM: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005667'),
        MM: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000178')
      };
      if (pickType !== null) {
        return pickTypeObj[pickType];
      } else {
        return '';
      }
    },
    pickStart() {
      // 开始拣货复核
      let v = this;
      if (v.pickList !== null && v.pickList !== '') {
        v.$Spin.show();
        v.axios.put(api.put_wmsPickingGoods_scanPickingGoodsNo + '/' + v.pickList + '?warehouseId=' + v.getWarehouseId()).then(response => {
          v.$Spin.hide();
          v.packingText = v.pickList;
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data.labelType === 'YMS' &&
                data.outboundType === 2) {
              v.$router.push('/packingWork/prepareGoods');
            } else {
              v.$router.push('/packingWork');
            }

          }
          v.pickList = '';
        });
      } else {
        v.pickingListModelStatus = false;
      }
    },
    cancel() {
      let v = this;
      v.pickingListModelStatus = false;
      v.$nextTick(() => {
        v.$refs.packListIpt.focus();
      });
    },
    hasPicking() {
      // 判断是否有正在处理的拣货复核
      let v = this;
      v.axios.get(api.get_wmsPickingGoods_getScanningPickingGoods).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let warehouseId = data.warehouseId;
            if (warehouseId === v.getWarehouseId()) {
              this.$Modal.confirm({
                title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000129'),
                content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005668'),
                onOk() {
                  v.pickList = response.data.datas.pickingGoodsNo;
                  v.pickStart();
                }
              });
            } else {
              let warehouse = this.$store.state.warehouseList.filter(i => i.warehouseId === warehouseId);
              let warehouseName = warehouse ? warehouse[0].warehouseName : '';
              if (warehouseName) {
                this.$Modal.confirm({
                  title: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000129'),
                  content: aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000252') + '[' + warehouseName + ']' + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1005669') + aliasb1f6d857944145d4ab8c800b5a1f7612.t('key1000252') + '[' + warehouseName + ']',
                  onOk() {
                    let arr = [...v.$store.state.warehouseList];
                    let wareItem = {};
                    arr.forEach(val => {
                      if (val.warehouseId === warehouseId) {
                        val.check = true;
                        wareItem = val;
                      } else {
                        val.check = false;
                      }
                    });
                    localStorage.setItem('warehouseId', wareItem.warehouseId);
                    commonSessionStorage.setItem('warehouseId', wareItem.warehouseId);
                    v.$store.commit('warehouseList', arr);
                    v.$store.commit('warehouseId', wareItem.warehouseId);
                    window.location.href = '#/packWorking';
                    window.location.reload();
                  }
                });
              }
            }
          }
        }
      });
    },
    getList() {
      let v = this;
      if (v.getPermission('wmsPickingGoods_getPackingPickingGoodsInfo')) {
        v.tableLoading = true;
        v.axios.get(api.get_wmsPickingGoods_getPackingPickingGoodsInfo + '?warehouseId=' + v.getWarehouseId()).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data.isPacking) {
              // 跳转到拣货复核页面
              if (data.labelType === 'YMS' &&
                data.outboundType === 2) {
                v.$router.push('/packingWork/prepareGoods');
              } else {
                v.$router.push('/packingWork');
              }
            }
            v.packingData = data.packingPickingGoods;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
            if (data.packingPickingPackageCount) {
              v.packingPickingPackageCount = data.packingPickingPackageCount;
            }
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError(); // 无权限
      }
    }
  },
  created() {
    let v = this;
    v.hasPicking();
    v.getUserName();
    v.getList();
  },
  updated: function () {
    this.$nextTick(() => {
      this.$refs.packListIpt.focus();
    });
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 22px;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}
</style>
